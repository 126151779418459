@import "../../styles/1-settings/breakpoints.scss";

.thumb-video {
	position: relative;

	&__thumbnail {
		width: 246px;
		max-width: calc(100% - 5px);

		img, iframe {
			max-width: 100%;
			max-height: 100%;
		}
	}
	&__thumbnail--picture {
		@media screen and (min-width: $screen-md-min) {
			display: block;
		}
		display: none;
	}

	&__thumbnail--video {
		@media screen and (min-width: $screen-md-min) {
			display: none;
		}
		display: block;
	}


	&__playicon {
		@media screen and (min-width: $screen-md-min) {
			display: block;
		}

		display: none;

		height: 55px;
		width: 55px;

		position: absolute;
		top: 50%;
		left: 50%;

		transform: translateX(-50%) translateY(-50%);

		polygon, circle {
			fill: none;
			stroke: #fff;
			stroke-miterlimit: 10;
			stroke-width: 2px;
		}
	}
}
