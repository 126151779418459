@import "../../styles/1-settings/breakpoints.scss";
@import "../../styles/1-settings/colors.scss";

.wrapper {
	position: relative;
	display: block;

	&--grey {
		background-color: #dbdbdb;
	}
	&--lightgrey {
		background-color: #ececec;
	}
	&--darkgrey {
		background-color: #4a4a4a;
	}
	&--black {
		background-color: #000000;
	}
	&--green {
		background-color: $background-green;
	}
	&--darkgreen {
		background-color: $background-darkgreen;
	}

	&--image {
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url("/media/moss.jpg");
		background-color: #000000;
	}

	&--bgstart {
		background-position: top center;
	}
	&--bgend {
		background-position: bottom center;
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
