#__bs_notify__ {
	top: 50% !important;
	right: 50% !important;
	transform: translate(50%, -50%);

	max-width: 400px;

	padding: 30px 20px !important;

	font-size: 15px !important;
	line-height: 25px;

	background-color: rgb(51, 51, 51) !important;
	border-radius: 5px !important;
	box-shadow: 0 2px 5px rgba(51,51,51,0.5);

	h1 {
		font-size: 23px;
		margin-bottom: 15px;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(239,239,239,0.15);
		text-transform: uppercase;
	}
}
