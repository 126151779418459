@import "../../styles/1-settings/breakpoints.scss";
@import "../../styles/1-settings/colors.scss";

.about {
	text-align: center;
	padding: 100px 30px;
	font-size: 15px;
	color: $default-text-color;
	@media (min-width: $screen-sm-min) {
		// padding: 100px 60px;
	}
	@media (min-width: $screen-md-min) {
		padding: 100px 0;
	}
	&__header {
		font-size: 40px;
		@media screen and (min-width: $screen-sm-min) {
			font-size: 45px;
		}
		font-weight: 700;
		color: $yellowgreen;
		margin-bottom: 18px;
	}
	&__lead {
		font-size: 22px;
		font-weight: 300;
		margin: 0 0 68px;
		color: $default-text-color;
	}
	&__body {
		column-count: 1;
		color: $default-text-color;
		column-gap: 35px;
		font-size: 15px;
		text-align: left;
		line-height: 24px;
		p:first-child {
			margin-top: 0;
		}
		@media (min-width: $screen-sm-min) {
			column-count: 2;
		}
		@media (min-width: $screen-md-min) {
			column-count: 3;
		}
	}
}
