@import "../../styles/1-settings/breakpoints.scss";

.practical-info {
	@media (min-width: $screen-sm-min) {
		padding: 123px;
	}

	background-position: top center;
	background-size: cover;

	padding: 123px 20px;

	text-align: center;
	color: #EFEFEF;

	&__header {
		color: #878939;
		margin-bottom: 47px;
	}
	&__next {
		margin-bottom: 73px;
	}
	&__content {
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
		flex: 1;
	}
	section {
		width: 100%;
		line-height: 27px;
		@media (min-width: $screen-sm-min) {
			width: 33%;
		}
		@media (min-width: $screen-md-min) {
		}
		h1 {
			font-family: Merriweather;
			font-size: 18px;
			font-weight: bold;
		}
		h2 {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 0;
		}
		p {
			margin: 0;
			font-weight: 300;
		}
	}
}
