@import "../../styles/2-tools/animation.scss";

.expand-area {
	display: block;
	padding: 35px 0 50px;
	position: relative;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	&__button {
		background: none;
		border: none;
		color: #9E973F;
		cursor: pointer;
		display: block;
		font-size: 20px;
		padding: 0 10px;
		margin: 0 auto;
		text-align: center;
		&:after {
			border-bottom: 2px solid #9E973F;
			border-right: 2px solid #9E973F;
			content: '';
			display: block;
			height: 20px;
			margin: 10px auto;
			transform: rotate(45deg);
			width: 20px;
		}
	}
	&__container {
		@include transition(null, all, .7s, ease-in-out);
		height: 0;
		overflow: hidden;
		position: relative;
		&.is-active {
			height: auto;
		}
	}
}
