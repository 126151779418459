@import "../../styles/1-settings/colors.scss";
@import "../../styles/1-settings/breakpoints.scss";

.grid {
	display: block;
	padding: 0;

	&--padding {
		padding: 105px 0;
	}

	&__child {
		align-content: flex-start;

		@media screen and (max-width: $screen-md) {
			width: 50%;
		}

		@media screen and (max-width: $screen-sml) {
			width: 100%;
		}
	}

	.expand-area & {
		padding-top: 0;
	}

	&__header {
		color: $yellowgreen-light;
		margin-bottom: 60px;
		margin-top: 0;
		text-align: center;
		text-transform: uppercase;
	}
}
