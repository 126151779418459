@import "../../styles/1-settings/breakpoints.scss";
@import "../../styles/1-settings/colors.scss";

.footer {
	padding: 56px 30px 80px;
	@media (min-width: $screen-sm-min) {
		padding: 56px 0 140px;
	}
	color: $default-text-color;
	font-style: normal;
	font-size: 14px;
	a {
		color: $default-text-color;
		text-decoration: none;
		font-style: normal;
	}
	.grid__child {
		margin-bottom: 2em;
		display: block;
	}
	&__org__name {
		font-style: normal;
		font-size: 16px;
		color: $default-text-color;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 2em;
	}
	&__org__address {
		color: $default-text-color;
		margin-bottom: 1em;
		font-style: normal;
	}
	&__social {
		background-color: #343926;
		width: 30px;
		height: 30px;
		display: block;
		float: left;
		border-radius: 30px;
		margin: -7px 20px 0 0;
		padding: 6px;
		text-align: center;
		svg {
			width: 100%;
		}
	}
	&__link {
		display: block;
		font-style: normal;
		& + & {
			margin-top: 5px;
		}
	}
}
