@import "../../styles/1-settings/breakpoints.scss";

.person {
	display: block;
	padding: 15px;
	text-align: center;

	@media screen and (min-width: $screen-md-min) {
		padding: 35px 55px;
	}

	&__image {
		border-radius: 50%;
		display: block;
		margin: 0 auto;
		max-width: 100%;
		max-height: 100%;
		width: 200px;
		height: auto;

		& + * {
			margin-top: 20px;
		}
	}

	&__name,
	&__role {
		display: block;
		font-family: Merriweather;
		font-size: 14px; // Should be mapped out as a variable when we have our fundamental CSS in place
		line-height: 20px; // Should be mapped out as a variable when we have our fundamental CSS in place
		color: #babcb4; // Should be mapped out as a variable when we have our fundamental CSS in place
	}

	&__name {
		font-weight: bold;
	}
}
