@import "../../styles/1-settings/breakpoints.scss";

$translateX: -45%; // Offset for video and video overlay

.hero {
//	background-image: #000 url(/images/001-hero/hero-background.jpg);
	background-color: #000;
	background-position: center;
	background-size: auto 100%;
	background-repeat: no-repeat;
	height: 800px;
	min-height: 400px;
	max-height: 100vh;
	position: relative;

	// Elements
	h1 {
		margin: 0;
		padding: 0;
		margin: 0;
		font-size: 87px;
		padding: 0;
		line-height: 133px;
		width: 530px;
		line-height: 1;
		position: absolute;
		text-transform: uppercase;
		top: 70px;
		left: 40px;
	}

	h2 {
		position: absolute;
		text-transform: uppercase;
		top: 240px;
		left: 160px;
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	// Bem
	&__logo {
		max-width: 100%;
		width: 373px;
		padding: 65px 60px;
		@media screen and (min-width: $screen-sm-min) {
			padding: 85px 45px;
		}
	}
	&__overlay-effect {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		filter: blur(1px);
	}

	// Class styling
	.border {
		z-index: 1;
		pointer-events: none;
		left: 50%;
		top: calc(50% - 90px);
		transform: translate(-50%, -50%);
		height: calc(100% - 180px);
		width: 100%;
		max-width: 1250px;
		position: absolute;
		margin: 20px 0;
		border: 10px solid rgba(255,255,255,0);

		&::before {
			content: '';
			border: 10px solid #fff;
			border-bottom: none;
			display: block;
			width: 0;
			animation:
				borderDraw 700ms forwards,
				borderDrawHeight 500ms 700ms forwards;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%,-10px);
		}
		&__bottom {
			border-bottom: 10px solid #fff;
			position: absolute;
			bottom: 0;
			width: 0;
			height: 10px;
			animation: borderDraw 700ms 1200ms forwards;
			&--left {
				left: 0;
			}
			&--right {
				right: 0;
			}
		}
	}

	@keyframes borderDraw {
	  from {
	    width: 0;
	  }
	  to {
	    width: 100%;
	  }
	}
	@keyframes borderDrawHeight {
	  from {
	    height: 0;
	  }
	  to {
	    height: 100%;
	  }
	}

	.container {
		position: relative;
		height: 100%;
		z-index: 1;
		pointer-events: none;
		* {
			pointer-events: all;
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}

		&--width {
			width: 100%;
			max-width: 1230px;
		}
	}

	&__video {
		overflow: hidden;
		width: 100%;
		height: 100%;
		position: absolute;
		/* Video Overlay */
		&__overlay {
			display: block;
			position: absolute;
			pointer-events: none;
			top: 50%;
			left: 50%;
			z-index: 1;
			min-height: 100%;
			max-height: 100vh;
			width: auto;
			height: auto;
			object-fit: cover;
			transform: translateX($translateX) translateY(-50%);
		}
	}
	video {
		position: absolute;
		top: 50%;
		left: 50%;
		min-height: 100%;
		max-height: 100vh;
		width: auto;
		height: auto;
		z-index: 1;
		object-fit: cover;
		transform: translateX($translateX) translateY(-50%);
		//background: url('/media/001-hero/IntoTheWoods_1440x800.jpg') 46% no-repeat;
		background-size: cover;
		transition: 1s opacity;
	}
}
