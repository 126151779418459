
.thumb-image {
	display: block;
	width: 246px;
	max-width: calc(100% - 5px);

	img {
		max-width: 100%;
		max-height: 100%;
	}
}
