@import "../../styles/1-settings/breakpoints.scss";

.people {
	padding: 105px 20px;
	@media (min-width: $screen-sm-min) {
		padding: 105px;
	}
	&__header {
		color: #9E973F;
		margin-bottom: 60px;
		margin-top: 0;
		text-align: center;
		text-transform: uppercase;
	}
	&--continuation {
		margin-top: -120px;
		@media screen and (min-width: $screen-md-min) {
			margin-top: -140px;
		}
	}
	&--continues {
		padding-bottom: 0;
		& + .expand-area button {
			margin-top: 105px;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		.person {
			width: 50%;
			float: left;
		}
		@media (min-width: $screen-sm-min) {
			.person {
				width: 50%;
				float: left;
			}
		}
		@media (min-width: $screen-md-min) {
			.person {
				width: 33%;
				float: left;
			}
		}
	}
}
