@import "../1-settings/breakpoints.scss";

h1, h2, h3, h4, h5, h6 {
}

h1, h2 {
	transition: font-size 200ms;
	font-size: 40px;
	@media screen and (min-width: $screen-sm-min) {
		font-size: 45px;
	}
}
