@import "../../styles/1-settings/breakpoints.scss";
@import "../../styles/1-settings/colors.scss";

.intro {
	text-align: center;
	padding: 100px 30px;
	color: $default-text-color;
	@media (min-width: $screen-sm-min) {
		padding: 100px;
	}
	font-size: 15px;
	&__header {
		text-transform: uppercase;
	}
	&__lead {
		font-size: 22px;
		font-weight: 300;
		margin: 10px 0 68px;
		line-height: 1.5;
	}
	&__body {
		column-count: 1;
		column-gap: 35px;
		font-size: 15px;
		text-align: left;
		line-height: 24px;
		font-weight: 300;
		max-width: 766px;
		margin: 0 auto;
		p:first-child {
			margin-top: 0;
		}
		@media (min-width: $screen-sm-min) {
			column-count: 2;
			column-width: 291px;
		}
	}
}
