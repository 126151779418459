@import "../../styles/1-settings/breakpoints.scss";

.image-carousel {
	display: block;
	position: relative;
	text-align: center;
	padding-top: 123px;

	&__header {
		color: #878939;
		text-transform: uppercase;
		font-size: 45px;
		font-weight: 700;
		margin-bottom: 60px;
		margin-top: 0;
		text-transform: uppercase
	}
}

.image-carousel__container {
	@media screen and (min-width: $screen-md-min) {
		padding: 0;
		display: block;
	}

	margin: auto;
	display: none;
	margin-bottom: 15px;

	font-size: 0;

	padding: 0 45px;

	> img {
		width: 100%;
	}
}

.image-carousel__video-container {
	width: 100%;
	padding-bottom: 56.25%;
	position: relative;

	iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		width: 100%;
		height: 100%;
	}
}
