@import "../../styles/2-tools/animation.scss";

// Animation for lazyloaded images
.lazyload {
	opacity: 0;

	transition: transform 150ms, opacity 0.7s ease-in-out;
	transform: translate(0, -10px);

	&.b-loaded {
		opacity: 1;
		transform: translate(0, 0);
	}
}
