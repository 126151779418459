@import "../../styles/1-settings/breakpoints.scss";
@import "../../styles/1-settings/colors.scss";

.press {
	@media (min-width: $screen-sm-min) {
		padding: 123px;
	}

	display: block;

	background-position: top center;
	background-size: cover;

	padding: 123px 20px;

	text-align: center;
	color: #EFEFEF;


	&__header {
    color: #878939;
    margin-bottom: 47px;
		
		margin-bottom: 60px;
		margin-top: 0;
		text-align: center;
		text-transform: uppercase;
	}

	&__cta {
		display: inline-block;

		width: 180px;

		background-repeat: no-repeat;
		background-color: $yellowgreen-light;
		background-position-y: center;
		
		line-height: 65px;
		text-decoration: none;
		color: #2a2c0c;

		margin: 0 30px 20px 30px;
		border: 0;

		&--image {
			background-image: url("/media/cta-image.svg");
			background-position-x: 35px;
			background-size: 37px 25px;
			
			padding-left: 72px;
		}
		&--document {
			background-image: url("/media/cta-document.svg");
			background-position-x: 15px;
			background-size: 25px 37px;

			padding-left: 40px;
		}
	}
}
