@import "../../styles/1-settings/breakpoints.scss";
@import "../../styles/1-settings/colors.scss";

.cta {
	font-size: 20px;
	font-weight: bold;
	padding: 17px 31px 15px;
	background: $yellowgreen-light;
	color: #2a2c0c;
	text-decoration: none;
	text-transform: uppercase;
	display: block;
	width: 100%;
	text-align: center;
	top: 0;
	z-index: 10;
	transition: background-color 100ms;

	&:hover {
		background: $yellowgreen;
	}

	@media screen and (min-width: $screen-xs-min) {
		font-size: 25px;
		padding: 27px 31px 25px;
		width: 335px;
	}

	@media screen and (min-width: $screen-sm-min) {
		padding: 47px 31px 45px;
		width: 365px;
	}
}

.cta-container {
	width: 100%;
	z-index: 2;
	position: absolute;
	right: 0;
	bottom: 0;

	@media screen and (min-width: $screen-xs-min) {
		width: 335px;
	}

	@media screen and (min-width: $screen-sm-min) {
		width: 365px;
	}
}
