@import "../../styles/1-settings/breakpoints.scss";

.carousel {
	position: relative;
	display: block;


	&__inner {
		display: block;
		transform: none;

		.carousel[data-shownelements="3"] &, 
		.carousel[data-shownelements="5"] &, 
		.carousel[data-shownelements="7"] &, 
		.carousel[data-shownelements="9"] & {
			@media screen and (max-width: $screen-md) {
				transform: translateX(-100%) !important;

				&--moveleft {
					transform: translateX(-50%) !important;
				}

				&--moveright {
					transform: translateX(-150%) !important;
				}
			}

			@media screen and (max-width: $screen-sml) {
				transform: translateX(-100%) !important;

				&--moveleft {
					transform: translateX(0) !important;
				}

				&--moveright {
					transform: translateX(-200%) !important;
				}
			}
		}


		.carousel[data-shownelements="3"] & {
			transform: translateX(calc(-100% / 3));

			&--moveleft {
				transform: translateX(0);
			}

			&--moveright {
				transform: translateX(calc(-100% / 3 * 2));
			}
		}

		.carousel[data-shownelements="5"] & {
			transform: translateX(calc(-100% / 5));

			&--moveleft {
				transform: translateX(0);
			}

			&--moveright {
				transform: translateX(calc(-100% / 5 * 2));
			}
		}

		.carousel[data-shownelements="7"] & {
			transform: translateX(calc(-100% / 7));

			&--moveleft {
				transform: translateX(0);
			}

			&--moveright {
				transform: translateX(calc(-100% / 7 * 2));
			}
		}

		.carousel[data-shownelements="9"] & {
			transform: translateX(calc(-100% / 9));

			&--moveleft {
				transform: translateX(0);
			}

			&--moveright {
				transform: translateX(calc(-100% / 9 * 2));
			}
		}



		// Modifiers
		&--moveleft, &--moveright {
			transition: 0.35s transform cubic-bezier(0.075, 0.82, 0.165, 1);
		}
	}

	//
	//
	// Children
	//

	&__children {
		display: none;
		white-space: nowrap;
		overflow: hidden;

		width: calc(100% - 70px);

		margin: auto;
		
		&--showchildren {
			display: block;
		}
	}

	//
	//
	// Navigation
	//

	&__navigation {
		position: absolute;
		top: 50%;
		left: 5px;
		right: 5px;
		
		margin-top: -25px;
	}

	&__next,
	&__prev {
		display: flex;
		align-items: center;
		justify-content: center;

		border: 0;
    height: 50px;
    width: 50px;
    background-color: transparent;

    position: absolute;
    top: 0;

		cursor: pointer;

		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		outline: none;
		-webkit-appearance: none;

		&:before {
			content: " ";

			position: relative;

			transform-origin: 50% 50%;

			height: 20px;
			width: 20px;
			background-color: transparent;

			border-bottom: 1px solid white;
		}
	}


	&__prev {
		@media screen and (min-width: $screen-md-min) {
			left: 0;
		}

		left: 10px;

		&:before {
			border-left: 1px solid white;
			transform: rotateZ(45deg);
		}
	}

	&__next {
		@media screen and (min-width: $screen-md-min) {
			right: 0;
		}

    right: 10px;

		&:before {
			border-right: 1px solid white;
			transform: rotateZ(-45deg);
		}
	}
}


.child {
	display: inline-block;
	white-space: initial;

	vertical-align: top;

	// Catch all sub elements
	& > * {
		margin: auto;
	}

	// Bem
	.carousel[data-shownelements="3"] & {
		@media screen and (max-width: $screen-md) {
			width: 50%;
		}

		@media screen and (max-width: $screen-sml) {
			width: 100%;
		}
		
		width: calc(100% / 3);
	}

	.carousel[data-shownelements="5"] & {
		@media screen and (max-width: $screen-md) {
			width: 50%;
		}
		@media screen and (max-width: $screen-sml) {
			width: 100%;
		}
		
		width: calc(100% / 5);
	}

	.carousel[data-shownelements="7"] & {
		@media screen and (max-width: $screen-md) {
			width: 50%;
		}
		@media screen and (max-width: $screen-sml) {
			width: 100%;
		}
		
		width: calc(100% / 7);
	}

	.carousel[data-shownelements="9"] & {
		@media screen and (max-width: $screen-md) {
			width: 50%;
		}
		@media screen and (max-width: $screen-sml) {
			width: 100%;
		}
		
		width: calc(100% / 9);
	}
}

.child-inner {
	display: flex;
	justify-content: center;
}


.child {
	display: inline-block;
	white-space: initial;

	// Catch all sub elements
	& > * {
		margin: auto;
	}

	// Bem
	.carousel[data-shownelements="3"] & {
		@media screen and (max-width: $screen-md) {
			width: 100%;
		}
		
		width: calc(100% / 3);
	}

	.carousel[data-shownelements="5"] & {
		@media screen and (max-width: $screen-md) {
			width: 100%;
		}
		
		width: calc(100% / 5);
	}

	.carousel[data-shownelements="7"] & {
		@media screen and (max-width: $screen-md) {
			width: 100%;
		}
		
		width: calc(100% / 7);
	}

	.carousel[data-shownelements="9"] & {
		@media screen and (max-width: $screen-md) {
			width: 100%;
		}
		
		width: calc(100% / 9);
	}
}

.child-inner {
	display: flex;
	justify-content: center;
}
