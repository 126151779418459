.review {
	text-align: center;
	max-width: 300px;
	font-weight: 300;
	color: #efefef;
	padding: 0 10px 30px 10px;

	&__stars {
		font-size: 0;
		margin: 0 0 15px 0;
	}
	&__body {
		line-height: 20px;
		margin-top: 0;
		margin-bottom: 20px;
		font-size: 12px;
	}
	&__publisher {	
		margin-bottom: 10px;
		margin-top: 0;

		font-size: 15px;
		font-weight: 700;
	}
	&__author {
		font-size: 12px;

		margin-top: 0;
	}

	&__star {
		width: 28px;
		height: 28px;
		margin: 0 4px;
	}
}
